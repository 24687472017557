import { call, put, takeLatest, throttle } from 'redux-saga/effects';
import {
  fetchNetworkFailure,
  fetchNetworkRequest,
  fetchNetworkScriptsSuccess,
  fetchNetworkSuccess,
} from '../actions/networkActions';
import SearchClient from '../../api/SearchClient';
import { errorAllCompanies, loadAllCompanies, loadedAllCompanies } from '../actions/companies';
import GetroClient from '../../api/GetroClient';
import NetworkAPI from '../../api/NetworkAPI';

export function* fetchNetworkRequestWorker({ payload: networkId }) {
  try {
    const network = yield call(NetworkAPI.show, networkId);
    const heroNumbers = yield call(SearchClient.getHeroNumbers, network);
    yield put(
      fetchNetworkSuccess({
        ...network,
        ...heroNumbers,
      }),
    );

    const scripts = yield call(GetroClient.getScripts, { network });
    yield put(fetchNetworkScriptsSuccess(scripts));
  } catch (e) {
    yield put(fetchNetworkFailure());
  }
}

export function* allCompaniesEvent({ payload }) {
  const { network } = payload;
  try {
    const response = yield call(GetroClient.getAllCompanies, { networkId: network.id });
    yield put(loadedAllCompanies(response));
  } catch (e) {
    yield put(errorAllCompanies(e));
  }
}

export default function* networkInformationRequest() {
  yield takeLatest(fetchNetworkRequest().type, fetchNetworkRequestWorker);
  yield throttle(1000, loadAllCompanies().type, allCompaniesEvent);
}
