import getConfig from 'next/config';
import axios from 'axios';
import SentryAPI from './SentryAPI';
import logger from '../helpers/logger';

const CALLBACK_PATH = 'users/auth/onboarding/linkedin/callback';
const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const redirectUri = () => {
  const { location } = window;
  const { protocol, hostname, port } = location;
  // istanbul ignore next
  const BASE_URL = `${protocol}//${hostname}${port ? `:${port}` : ''}/`;
  return `${BASE_URL}${CALLBACK_PATH}`;
};

class LinkedInAPI {
  static requestAuthorizationCode() {
    const { linkedinKey } = publicRuntimeConfig;

    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedinKey}&scope=r_emailaddress%20r_liteprofile&state=123456&redirect_uri=${redirectUri()}`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2 + window.screenX;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      oauthUrl,
      'Linkedin',
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${width},height=${height},top=${top},left=${left}`,
    );
  }

  static async getAccessToken(baseUrl, code) {
    const { linkedinSecret } = serverRuntimeConfig;
    const { linkedinKey } = publicRuntimeConfig;

    try {
      const response = await axios.post(
        'https://www.linkedin.com/oauth/v2/accessToken',
        {},
        {
          params: {
            grant_type: 'authorization_code',
            code,
            redirect_uri: `${baseUrl}${CALLBACK_PATH}`,
            client_id: linkedinKey,
            client_secret: linkedinSecret,
          },
        },
      );

      const { data } = response;
      const { access_token: accessToken = null } = data;

      return accessToken;
    } catch (e) {
      logger.error({ e }, 'Failed to retrieve access token data from LinkedIn');
      SentryAPI.trackError('Failed to retrieve access token data from LinkedIn', { e });
      return null;
    }
  }

  static async authorizedGetRequest(url, accessToken) {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  static async getUserData(accessToken) {
    try {
      const response = await LinkedInAPI.authorizedGetRequest(
        'https://api.linkedin.com/v2/me?projection=(id,profilePicture(displayImage~digitalmediaAsset:playableStreams),localizedFirstName,localizedLastName)',
        accessToken,
      );

      const { data } = response;
      return data;
    } catch (e) {
      logger.error({ e }, 'Failed to retrieve user data from LinkedIn');
      SentryAPI.trackError('Failed to retrieve user data from LinkedIn', { e });
      return null;
    }
  }

  static async getEmailAddress(accessToken) {
    try {
      const response = await LinkedInAPI.authorizedGetRequest(
        'https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))',
        accessToken,
      );

      const { data } = response;
      return data;
    } catch (e) {
      logger.error({ e }, 'Failed to retrieve user email address from LinkedIn');
      SentryAPI.trackError('Failed to retrieve user email address from LinkedIn', { e });
      return null;
    }
  }

  static async composeUserInformation(accessToken) {
    let userData = {
      linkedinId: '',
      firstName: '',
      lastName: '',
      avatarUrl: '',
      emailAddress: '',
    };

    const userDataResult = await LinkedInAPI.getUserData(accessToken);
    if (userDataResult) {
      const {
        id: linkedinId,
        localizedFirstName: firstName,
        localizedLastName: lastName,
        profilePicture,
      } = userDataResult;

      const { 'displayImage~': displayImage } = profilePicture || {};
      const { elements } = displayImage || {};
      const [avatarImage] = elements || [];
      const { identifiers } = avatarImage || {};
      const [firstImageIdentifier] = identifiers || [];
      const { identifier: avatarUrl = '' } = firstImageIdentifier || {};

      userData = {
        ...userData,
        linkedinId,
        firstName,
        lastName,
        avatarUrl,
      };
    }

    const emailAddressResult = await LinkedInAPI.getEmailAddress(accessToken);
    if (emailAddressResult) {
      const { elements } = emailAddressResult;
      const [contactData] = elements;
      const { emailAddress = '' } = contactData['handle~'] || {};

      userData = {
        ...userData,
        emailAddress,
      };
    }

    return userData;
  }
}

export default LinkedInAPI;
