import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import LinkedInAPI from '../../../api/LinkedInAPI';

const LinkedInButton = ({ title }) => {
  const onClick = (event) => {
    event.preventDefault();
    LinkedInAPI.requestAuthorizationCode();
  };

  return (
    <Button
      onClick={onClick}
      type="button"
      justifyContent="center"
      display="flex"
      variant="linkedIn"
      height="48px"
      alignItems="center"
    >
      <Image sx={{ width: '16px', mr: 3 }} src="/icon-linkedin.png" alt="Icon LinkedIn" />
      {title}
    </Button>
  );
};

LinkedInButton.propTypes = {
  title: PropTypes.string,
};

LinkedInButton.defaultProps = {
  title: 'Connect with LinkedIn',
};

export default LinkedInButton;
